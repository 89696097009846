<template>
    <div>
        <card-header :title="'Day ' + day.number + ': ' + dayDate" icon="fa-calendar-day" :subheader="day.title"/>
        <card-toolbar>
            <button @click="$openCard('edit-day', {tripId: trip.id, dayId: day.id}, card)"><i class="fas fa-pencil mr-2"/>Edit Details</button>
            <button @click="$openCard('new-event', {tripId: trip.id, dayId: day.id}, card)"><i class="fas fa-plus mr-2"/>Event</button>
            <button @click="$openCard('new-day-photo', {tripId: trip.id, dayId: day.id}, card)"><i class="fas fa-plus mr-2"/>Photo</button>
        </card-toolbar>

        <card-body>
            <card-list v-if="day.deleted">
                <container-list-item padded class=" pb-1 mb-0 content">
                    <h5 class="has-text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>This day has been deleted.</h5>
                </container-list-item>
            </card-list>
            <img alt="day" v-if="day.photo && day.photo.file" :src="apiUrl + 'api/day_photos/img/' + day.photo.file + '/day'">
            <card-list>
                <day-list-item :card="card" :day="day"/>
                <subheader-list-item title="Events" icon="fa-users" :badge="day.events.length"/>
                <event-list-item
                        :card="card"
                        :event="event"
                        v-for="event in day.events"
                        :active="(child && child.definition.component === 'edit-event') && child.props.eventId === event.id.toString()"
                        @click="$openCard('edit-event', {tripId: trip.id, dayId: day.id, eventId: event.id}, card)"
                />
                <subheader-list-item title="Photos" icon="fa-images" :badge="day.photos.length"/>
                <draggable v-model="day.photos" handle=".drag-handle" @end="completeDrag">
                    <transition-group>
                        <day-photo-list-item
                            :photo="p"
                            :card="card"
                            :key="p.id"
                            :active="(child && child.definition.component === 'edit-day-photo') && child.props.photoId === p.id.toString()"
                            v-for="p in day.photos"
                            @click="$openCard('edit-day-photo', {dayId: day.id, photoId: p.id}, card)"
                            class="t-card-list-item"
                        />
                    </transition-group>
                </draggable>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import CardBody from "../../TIER/components/CardBody";
    import DayListItem from "../../components/DayListItem";
    import EventListItem from "../../components/EventListItem";
    import draggable from "vuedraggable";

    import {client as http} from "../../http_client";
    import PhotoListItem from "@/components/PhotoListItem";
    import DayPhotoListItem from "@/components/DayPhotoListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import moment from 'moment';

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, DayPhotoListItem, PhotoListItem, EventListItem, DayListItem, CardBody, SubheaderListItem, CardToolbar, CardList, CardHeader, draggable},
        data: function() {
            return {
                day: {
                    events: [],
                    photos: []
                },
                trip: {},
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/'
            }
        },
        computed: {
            dayDate: function() {
                return moment(this.day.day_on).format('ddd, MMM D, YYYY');
            }
        },
        methods: {
            loadDay: function(force) {
                this.$emit('loading', true);
                http.get('/api/days/' + this.props.dayId, {force}).then(response => {
                    this.day = response.data;
                    this.$emit('title', this.day.title);

                    http.get('/api/trips/' + this.day.trip_id).then(response => {
                        this.trip = response.data;
                        this.$emit('loading', false);
                    });
                });
            },
            reload: function() {
                this.loadDay(true);
            },
            completeDrag: function() {
                http.post('/api/day_photos/order', this.day.photos.map(p => p.id)).then(response => {

                });
            }
        },
        watch: {
            'props.dayId': function() {
                this.loadDay(true);
            }
        },
        mounted() {
            this.loadDay();
        }
    };
</script>

<style lang="scss" scoped>

</style>
